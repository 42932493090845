import React, { useState } from "react";
import { FirebaseDatabaseNode } from "@react-firebase/database";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItemComponent from "../../components/ListItem/ListItem";
import MuiAlert from "@material-ui/lab/Alert";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  is_not_read: {
    backgroundColor: "#f3f1f1",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const collectionPath = "/bug-reports/";
export default function BugReportsPage() {
  const classes = useStyles();
  const [limit, setLimit] = useState(15);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");

  const setSnackbar = (message, type = "success") => {
    setSnackbarMessage(message);
    setSnackbarType(type);
  };

  const snackbarHandleClose = () => {
    setSnackbarMessage("");
  };

  const onLoadMore = () => {
    setLimit(limit + 15);
  };

  return (
    <div>
      <List className={classes.root}>
        <FirebaseDatabaseNode
          path={collectionPath}
          limitToLast={limit}
          orderByKey
        >
          {({ value, isLoading }) => {
            return (
              <React.Fragment>
                {value !== null ? (
                  Object.entries(value)
                    .reverse()
                    .map((report) => (
                      <ListItemComponent
                        id={report[0]}
                        item={report[1]}
                        setSnackbar={setSnackbar}
                        collectionPath={collectionPath}
                      />
                    ))
                ) : (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                {value !== null ? (
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!!!value}
                      onClick={onLoadMore}
                      className={classes.buttonProgress}
                    >
                      more
                    </Button>
                  </div>
                ) : null}
              </React.Fragment>
            );
          }}
        </FirebaseDatabaseNode>
      </List>

      <Snackbar
        open={!!snackbarMessage.length}
        autoHideDuration={6000}
        onClose={snackbarHandleClose}
      >
        <Alert onClose={snackbarHandleClose} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
