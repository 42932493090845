import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import MarkunreadIcon from "@material-ui/icons/Markunread";
import { IconButton, makeStyles, Tooltip, withStyles } from "@material-ui/core";
import { FirebaseDatabaseMutation } from "@react-firebase/database";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  is_not_read: {
    backgroundColor: "#f3f1f1",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function ListItemComponent(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <ListItem
        className={!Number(props.item.is_read) ? classes.is_not_read : null}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          <Avatar alt="Some dude" />
        </ListItemAvatar>
        <ListItemText
          primary={<Moment date={new Date(Number(props.item.created_at))} />}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                <a href={"mailto:" + props.item.email}>{props.item.email}</a>
              </Typography>
              {" — " + props.item.description}
              {props.item.page_url ? (
                <Typography>
                  <a
                    href={props.item.page_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.item.page_url}
                  </a>
                </Typography>
              ) : null}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <FirebaseDatabaseMutation
            path={props.collectionPath + props.id}
            type="update"
          >
            {({ runMutation }) => (
              <IconButton
                edge="end"
                aria-label="mark-as-read"
                onClick={() => {
                  runMutation({
                    is_read: 1,
                  })
                    .then(() => {
                      props.setSnackbar("Message has been marked as read");
                    })
                    .catch((e) => {
                      props.setSnackbar("Something went wrong..", "error");
                    });
                }}
                color={props.item.is_read ? "" : "primary"}
              >
                <MarkunreadIcon />
              </IconButton>
            )}
          </FirebaseDatabaseMutation>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography>
                  {props.item.browser.name} {props.item.browser.version}{" "}
                  {props.item.browser.os}
                </Typography>
              </React.Fragment>
            }
          >
            <IconButton edge="end" aria-label="info">
              <InfoIcon />
            </IconButton>
          </HtmlTooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </React.Fragment>
  );
}
