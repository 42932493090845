import React from "react";
// MUI Core
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { useForm } from "react-hook-form";
import firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
}));

const SignInPage = () => {
  const { handleSubmit, register } = useForm();
  const classes = useStyles();

  const onSubmitForm = handleSubmit((data) => {
    console.log("onSubmitForm", data);
    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then((resp) => {
        console.log("auth response", resp);
      })
      .catch((err) => console.error(err));
  });

  return (
    <Container className={classes.container} maxWidth="xs">
      <form method="post" onSubmit={onSubmitForm}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="warning">Authorization required</Alert>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  size="small"
                  variant="outlined"
                  required
                  inputRef={register}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  size="small"
                  type="password"
                  variant="outlined"
                  required
                  inputRef={register}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Log in
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default SignInPage;
