import HomePage from "../pages/Home/HomePage";
import BugReportsPage from "../pages/BugReports/BugReportsPage";
import UninstallFeedbackPage from "../pages/UninstallFeedback/UninstallFeedbackPage";

const routes = [
  { path: "/", component: HomePage, exact: true },
  { path: "/bug-reports", component: BugReportsPage },
  { path: "/uninstall-feedback", component: UninstallFeedbackPage },
  { path: "/settings", render: () => "test" },
];

export default routes;
