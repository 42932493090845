import { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Moment from "react-moment";
import ErrorIcon from "@material-ui/icons/Error";
import IconButton from "@material-ui/core/IconButton";
import {
  Badge,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { FirebaseDatabaseMutation } from "@react-firebase/database";
import MarkunreadIcon from "@material-ui/icons/Markunread";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function AccordionListItemComponent(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("-MRL08F_2kZviEsQCbRH");

  const handleChange = (panel) => (event, isExpanded) => {
    const out = isExpanded ? panel : false;
    setExpanded(out);
  };

  return (
    <Accordion
      expanded={expanded === props.item.id}
      onChange={handleChange(props.item.id)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <IconButton>
          {props.item.is_read ? (
            <ErrorIcon />
          ) : (
            <Badge color="secondary" variant="dot">
              <ErrorIcon />
            </Badge>
          )}
        </IconButton>
        <Typography className={classes.heading}>
          <Moment date={props.item.created_at} />
        </Typography>
        <Typography className={classes.secondaryHeading}>
          {props.item.browser
            ? props.item.browser.name +
              " " +
              props.item.browser.version +
              " " +
              props.item.browser.os
            : null}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Typography>{props.item.reasonText}</Typography>
        </div>
        {typeof props.item.reasons === "object" ? (
          <div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={""}
                onChange={handleChange}
              >
                {Object.entries(props.item.reasons).map((reason, key) => {
                  return (
                    <FormControlLabel
                      value="disabled"
                      control={<Radio checked={true} />}
                      label={reason[1]}
                      key={key}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        ) : null}
        <div>
          <FirebaseDatabaseMutation
            path={props.collectionPath + props.id}
            type="update"
          >
            {({ runMutation }) => (
              <IconButton
                edge="end"
                aria-label="mark-as-read"
                onClick={() => {
                  runMutation({
                    is_read: 1,
                  })
                    .then(() => {
                      props.setSnackbar("Message has been marked as read");
                    })
                    .catch((e) => {
                      props.setSnackbar("Something went wrong..", "error");
                    });
                }}
              >
                <MarkunreadIcon />
              </IconButton>
            )}
          </FirebaseDatabaseMutation>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
