import { Box, Container, CssBaseline } from "@material-ui/core";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HeaderComponent from "./components/Header/HeaderComponent";
import routes from "./routes/routes";
import "firebase/database";
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import firebase from "firebase/app";
import "firebase/auth";
import { config } from "./firebase/firebase-config";
import {
  FirebaseAuthConsumer,
  FirebaseAuthProvider,
} from "@react-firebase/auth";
import SignInPage from "./pages/SignIn/SignInPage";

export default function App() {
  return (
    <FirebaseAuthProvider firebase={firebase} {...config}>
      <FirebaseDatabaseProvider {...config} firebase={firebase}>
        <BrowserRouter>
          <CssBaseline />
          <Container maxWidth="md">
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) =>
                isSignedIn ? (
                  <React.Fragment>
                    <Box m={1}>
                      <HeaderComponent />
                    </Box>
                    <Box m={1}>
                      <Switch>
                        {routes.map((route) => (
                          <Route key={route.path} {...route} />
                        ))}
                      </Switch>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box m={1}>
                      <SignInPage />
                    </Box>
                  </React.Fragment>
                )
              }
            </FirebaseAuthConsumer>
          </Container>
        </BrowserRouter>
      </FirebaseDatabaseProvider>
    </FirebaseAuthProvider>
  );
}
