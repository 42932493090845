import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import { useHistory, useLocation } from "react-router-dom";
import BugReportIcon from "@material-ui/icons/BugReport";
import FeedbackIcon from "@material-ui/icons/Feedback";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default function DrawerComponent(props) {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List>
        <ListItem
          button
          onClick={() => {
            props.handleMenuState(false);
            history.push("/");
          }}
          selected={location.pathname === "/"}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
      </List>

      <List>
        <ListItem
          button
          onClick={() => {
            props.handleMenuState(false);
            history.push("/bug-reports");
          }}
          selected={location.pathname === "/bug-reports"}
        >
          <ListItemIcon>
            <BugReportIcon />
          </ListItemIcon>
          <ListItemText primary={"Bug Reports"} />
        </ListItem>
      </List>

      <List>
        <ListItem
          button
          onClick={() => {
            props.handleMenuState(false);
            history.push("/uninstall-feedback");
          }}
          selected={location.pathname === "/uninstall-feedback"}
        >
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary={"Uninstall Feedback"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            props.handleMenuState(false);
            history.push("/settings");
          }}
          selected={location.pathname === "/settings"}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Drawer
      anchor={"left"}
      open={props.menuState}
      onClose={() => {
        props.handleMenuState(false);
      }}
    >
      {list("left")}
    </Drawer>
  );
}
