import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FirebaseDatabaseNode } from "@react-firebase/database";
import AccordionListItemComponent from "../../components/AccordionListItem/AccordionListItem";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
  },
}));

const collectionPath = "/uninstall-feedback/";
export default function ControlledAccordions() {
  const classes = useStyles();
  const [limit, setLimit] = useState(15);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbarType, setSnackbarType] = useState("success");

  const setSnackbar = (message, type = "success") => {
    setSnackbarMessage(message);
    setSnackbarType(type);
  };

  const snackbarHandleClose = () => {
    setSnackbarMessage("");
  };

  const onLoadMore = () => {
    setLimit(limit + 15);
  };
  return (
    <div className={classes.root}>
      <FirebaseDatabaseNode
        path={collectionPath}
        limitToLast={limit}
        orderByValue={"created_at"}
      >
        {({ value, isLoading }) => {
          return (
            <React.Fragment>
              {value !== null ? (
                Object.entries(value)
                  .reverse()
                  .map((report) => (
                    <AccordionListItemComponent
                      id={report[0]}
                      item={report[1]}
                      collectionPath={collectionPath}
                      setSnackbar={setSnackbar}
                      key={report[0]}
                    />
                  ))
              ) : (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {value !== null ? (
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!!!value}
                    onClick={onLoadMore}
                    className={classes.buttonProgress}
                  >
                    more
                  </Button>
                </div>
              ) : null}
            </React.Fragment>
          );
        }}
      </FirebaseDatabaseNode>
      <Snackbar
        open={!!snackbarMessage.length}
        autoHideDuration={6000}
        onClose={snackbarHandleClose}
      >
        <Alert onClose={snackbarHandleClose} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
